import React from "react";


import HomepageHero from "../components/sections/homepagehero"

import Layout from "../components/layoutComponents/layout";

import {useIntl} from "gatsby-plugin-intl";

export default function Home() {
  const intl = useIntl();

  return (
    <Layout title={intl.formatMessage({id: "home"})} className="home">
      <HomepageHero/>
    </Layout>
  );
}
