import * as React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import '../../styles/sections/homepagehero.scss'
import { useIntl } from "gatsby-plugin-intl"


export default function HomepageHero() {
    const intl = useIntl();
    const locale =  `/${intl.locale}`;
    return(
        <section className="homepageHero">
        <div className="inner">
        <h3>
            {intl.formatMessage({ id: "homepageSubTitle" })}
        </h3>
        <h1>
        {intl.formatMessage({ id: "homepageTitle" })}
        { intl.locale === 'cs' ? (
            <small>na volné noze</small>
        ):(null)
        }
        </h1>
        <div className="row">
            <AniLink paintDrip to={`${locale}/portfolio`} className="button" color="black">
                Portfolio
            </AniLink>
            <AniLink cover direction="left" duration={2} bg="#000" to={`${locale}/contact`} className="button">
            {intl.formatMessage({ id: "Contact" })}
            </AniLink>
        </div>
        </div>
    </section>
    )
}